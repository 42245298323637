import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9b3b49c2 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _9ed3f4f4 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _a98d4118 = () => interopDefault(import('../pages/card/index.vue' /* webpackChunkName: "pages/card/index" */))
const _bf07cc68 = () => interopDefault(import('../pages/insurance/index.vue' /* webpackChunkName: "pages/insurance/index" */))
const _495aff1b = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _50087b2e = () => interopDefault(import('../pages/logout/index.vue' /* webpackChunkName: "pages/logout/index" */))
const _c3266500 = () => interopDefault(import('../pages/mortgage/index.vue' /* webpackChunkName: "pages/mortgage/index" */))
const _8f5c5510 = () => interopDefault(import('../pages/mortgage-match/index.vue' /* webpackChunkName: "pages/mortgage-match/index" */))
const _04533bde = () => interopDefault(import('../pages/p-loan/index.vue' /* webpackChunkName: "pages/p-loan/index" */))
const _61c567fa = () => interopDefault(import('../pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _5906e37b = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _1b1da0ca = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _23e33dba = () => interopDefault(import('../pages/query/index.vue' /* webpackChunkName: "pages/query/index" */))
const _e96baa72 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _404fec22 = () => interopDefault(import('../pages/terms-conditions/index.vue' /* webpackChunkName: "pages/terms-conditions/index" */))
const _32a4f56c = () => interopDefault(import('../pages/vendor/index.vue' /* webpackChunkName: "pages/vendor/index" */))
const _71045182 = () => interopDefault(import('../pages/application/done.vue' /* webpackChunkName: "pages/application/done" */))
const _b6338b20 = () => interopDefault(import('../pages/application/funding/index.vue' /* webpackChunkName: "pages/application/funding/index" */))
const _c1a499c2 = () => interopDefault(import('../pages/application/mortgage/index.vue' /* webpackChunkName: "pages/application/mortgage/index" */))
const _7a822370 = () => interopDefault(import('../pages/application/p-loan/index.vue' /* webpackChunkName: "pages/application/p-loan/index" */))
const _54f4fed0 = () => interopDefault(import('../pages/blog/all/index.vue' /* webpackChunkName: "pages/blog/all/index" */))
const _2741af32 = () => interopDefault(import('../pages/enquiry/done/index.vue' /* webpackChunkName: "pages/enquiry/done/index" */))
const _60522983 = () => interopDefault(import('../pages/enquiry/investment/index.vue' /* webpackChunkName: "pages/enquiry/investment/index" */))
const _5d08320d = () => interopDefault(import('../pages/form/ua_prefill.vue' /* webpackChunkName: "pages/form/ua_prefill" */))
const _47a9e830 = () => interopDefault(import('../pages/insurance/auto/index.vue' /* webpackChunkName: "pages/insurance/auto/index" */))
const _318d7b29 = () => interopDefault(import('../pages/insurance/decoration/index.vue' /* webpackChunkName: "pages/insurance/decoration/index" */))
const _6ffe0ba7 = () => interopDefault(import('../pages/insurance/helper/index.vue' /* webpackChunkName: "pages/insurance/helper/index" */))
const _db335da6 = () => interopDefault(import('../pages/insurance/labor/index.vue' /* webpackChunkName: "pages/insurance/labor/index" */))
const _6efb609e = () => interopDefault(import('../pages/insurance/store/index.vue' /* webpackChunkName: "pages/insurance/store/index" */))
const _0fb1b7da = () => interopDefault(import('../pages/insurance/travel/index.vue' /* webpackChunkName: "pages/insurance/travel/index" */))
const _03dbe788 = () => interopDefault(import('../pages/login/forgotpassword/index.vue' /* webpackChunkName: "pages/login/forgotpassword/index" */))
const _0e996122 = () => interopDefault(import('../pages/oauth2/redirect/index.vue' /* webpackChunkName: "pages/oauth2/redirect/index" */))
const _35183996 = () => interopDefault(import('../pages/p-loan/calculator/index.vue' /* webpackChunkName: "pages/p-loan/calculator/index" */))
const _26b09b32 = () => interopDefault(import('../pages/p-loan/compare/index.vue' /* webpackChunkName: "pages/p-loan/compare/index" */))
const _9a5b80d0 = () => interopDefault(import('../pages/p-loan/form/index.vue' /* webpackChunkName: "pages/p-loan/form/index" */))
const _caf6d858 = () => interopDefault(import('../pages/p-loan/index copy.vue' /* webpackChunkName: "pages/p-loan/index copy" */))
const _23df17a0 = () => interopDefault(import('../pages/p-loan/lead/index.vue' /* webpackChunkName: "pages/p-loan/lead/index" */))
const _2bd557be = () => interopDefault(import('../pages/p-loan/recommend/index.vue' /* webpackChunkName: "pages/p-loan/recommend/index" */))
const _068ea6fe = () => interopDefault(import('../pages/p-loan/short/index.vue' /* webpackChunkName: "pages/p-loan/short/index" */))
const _ef4ca128 = () => interopDefault(import('../pages/profile/contact/index.vue' /* webpackChunkName: "pages/profile/contact/index" */))
const _7298d7ad = () => interopDefault(import('../pages/profile/loan_status/index.vue' /* webpackChunkName: "pages/profile/loan_status/index" */))
const _2d135162 = () => interopDefault(import('../pages/profile/requirement/index.vue' /* webpackChunkName: "pages/profile/requirement/index" */))
const _4f9006f6 = () => interopDefault(import('../pages/profile/resetpassword/index.vue' /* webpackChunkName: "pages/profile/resetpassword/index" */))
const _323b3139 = () => interopDefault(import('../pages/profile/reward/index.vue' /* webpackChunkName: "pages/profile/reward/index" */))
const _7865d880 = () => interopDefault(import('../pages/vendor/checkid/index.vue' /* webpackChunkName: "pages/vendor/checkid/index" */))
const _6c1770c6 = () => interopDefault(import('../pages/vendor/login/index.vue' /* webpackChunkName: "pages/vendor/login/index" */))
const _2288e5ad = () => interopDefault(import('../pages/application/components/CommonFields.vue' /* webpackChunkName: "pages/application/components/CommonFields" */))
const _f331e718 = () => interopDefault(import('../pages/application/components/ContactTime.vue' /* webpackChunkName: "pages/application/components/ContactTime" */))
const _28c66167 = () => interopDefault(import('../pages/application/components/Identity.vue' /* webpackChunkName: "pages/application/components/Identity" */))
const _234e3f8c = () => interopDefault(import('../pages/application/components/Step1.vue' /* webpackChunkName: "pages/application/components/Step1" */))
const _235c570d = () => interopDefault(import('../pages/application/components/Step2.vue' /* webpackChunkName: "pages/application/components/Step2" */))
const _236a6e8e = () => interopDefault(import('../pages/application/components/Step3.vue' /* webpackChunkName: "pages/application/components/Step3" */))
const _2378860f = () => interopDefault(import('../pages/application/components/Step4.vue' /* webpackChunkName: "pages/application/components/Step4" */))
const _0f666cb7 = () => interopDefault(import('../pages/application/components/StepShort.vue' /* webpackChunkName: "pages/application/components/StepShort" */))
const _4de3d7f2 = () => interopDefault(import('../pages/insurance/travel/apply/index.vue' /* webpackChunkName: "pages/insurance/travel/apply/index" */))
const _70ce73d8 = () => interopDefault(import('../pages/p-loan/short/agree.vue' /* webpackChunkName: "pages/p-loan/short/agree" */))
const _43b896a4 = () => interopDefault(import('../pages/application/additional/components/Step1.vue' /* webpackChunkName: "pages/application/additional/components/Step1" */))
const _43d4c5a6 = () => interopDefault(import('../pages/application/additional/components/Step3.vue' /* webpackChunkName: "pages/application/additional/components/Step3" */))
const _5658d51a = () => interopDefault(import('../pages/application/approve/components/ApproveForm.vue' /* webpackChunkName: "pages/application/approve/components/ApproveForm" */))
const _ed5146d2 = () => interopDefault(import('../pages/application/approve/components/BorrowerInfo.vue' /* webpackChunkName: "pages/application/approve/components/BorrowerInfo" */))
const _58574ad1 = () => interopDefault(import('../pages/application/approve/components/LenderInfo.vue' /* webpackChunkName: "pages/application/approve/components/LenderInfo" */))
const _42a13761 = () => interopDefault(import('../pages/application/commercial/components/Step1.vue' /* webpackChunkName: "pages/application/commercial/components/Step1" */))
const _42af4ee2 = () => interopDefault(import('../pages/application/commercial/components/Step2.vue' /* webpackChunkName: "pages/application/commercial/components/Step2" */))
const _42bd6663 = () => interopDefault(import('../pages/application/commercial/components/Step3.vue' /* webpackChunkName: "pages/application/commercial/components/Step3" */))
const _42cb7de4 = () => interopDefault(import('../pages/application/commercial/components/Step4.vue' /* webpackChunkName: "pages/application/commercial/components/Step4" */))
const _4bc11be8 = () => interopDefault(import('../pages/application/commercial/components/StepShort.vue' /* webpackChunkName: "pages/application/commercial/components/StepShort" */))
const _76515144 = () => interopDefault(import('../pages/application/funding/components/Step1.vue' /* webpackChunkName: "pages/application/funding/components/Step1" */))
const _76352242 = () => interopDefault(import('../pages/application/funding/components/Step2.vue' /* webpackChunkName: "pages/application/funding/components/Step2" */))
const _7618f340 = () => interopDefault(import('../pages/application/funding/components/Step3.vue' /* webpackChunkName: "pages/application/funding/components/Step3" */))
const _1fb62962 = () => interopDefault(import('../pages/application/mortgage/components/Step1.vue' /* webpackChunkName: "pages/application/mortgage/components/Step1" */))
const _1f99fa60 = () => interopDefault(import('../pages/application/mortgage/components/Step2.vue' /* webpackChunkName: "pages/application/mortgage/components/Step2" */))
const _1f7dcb5e = () => interopDefault(import('../pages/application/mortgage/components/Step3.vue' /* webpackChunkName: "pages/application/mortgage/components/Step3" */))
const _1f619c5c = () => interopDefault(import('../pages/application/mortgage/components/Step4.vue' /* webpackChunkName: "pages/application/mortgage/components/Step4" */))
const _6946b582 = () => interopDefault(import('../pages/application/submitted/components/ApplySubmitted.vue' /* webpackChunkName: "pages/application/submitted/components/ApplySubmitted" */))
const _507b859e = () => interopDefault(import('../pages/p-loan/lead/components/Step1.vue' /* webpackChunkName: "pages/p-loan/lead/components/Step1" */))
const _50899d1f = () => interopDefault(import('../pages/p-loan/lead/components/Step2.vue' /* webpackChunkName: "pages/p-loan/lead/components/Step2" */))
const _5097b4a0 = () => interopDefault(import('../pages/p-loan/lead/components/Step3.vue' /* webpackChunkName: "pages/p-loan/lead/components/Step3" */))
const _50a5cc21 = () => interopDefault(import('../pages/p-loan/lead/components/Step4.vue' /* webpackChunkName: "pages/p-loan/lead/components/Step4" */))
const _50b3e3a2 = () => interopDefault(import('../pages/p-loan/lead/components/Step5.vue' /* webpackChunkName: "pages/p-loan/lead/components/Step5" */))
const _296c7fa4 = () => interopDefault(import('../pages/p-loan/lead/components/Vendors.vue' /* webpackChunkName: "pages/p-loan/lead/components/Vendors" */))
const _ff05b3e0 = () => interopDefault(import('../pages/p-loan/short/components/Step1.vue' /* webpackChunkName: "pages/p-loan/short/components/Step1" */))
const _fee984de = () => interopDefault(import('../pages/p-loan/short/components/Step2.vue' /* webpackChunkName: "pages/p-loan/short/components/Step2" */))
const _fecd55dc = () => interopDefault(import('../pages/p-loan/short/components/Step3.vue' /* webpackChunkName: "pages/p-loan/short/components/Step3" */))
const _58e02f9e = () => interopDefault(import('../pages/application/approve/second/_slug.vue' /* webpackChunkName: "pages/application/approve/second/_slug" */))
const _5930db90 = () => interopDefault(import('../pages/application/offer/confirm/_slug.vue' /* webpackChunkName: "pages/application/offer/confirm/_slug" */))
const _4c2d36aa = () => interopDefault(import('../pages/insurance/travel/apply/_slug.vue' /* webpackChunkName: "pages/insurance/travel/apply/_slug" */))
const _3ddd29a2 = () => interopDefault(import('../pages/application/additional/_slug.vue' /* webpackChunkName: "pages/application/additional/_slug" */))
const _60b2b578 = () => interopDefault(import('../pages/application/approve/_slug.vue' /* webpackChunkName: "pages/application/approve/_slug" */))
const _2a14eece = () => interopDefault(import('../pages/application/checkid/_slug.vue' /* webpackChunkName: "pages/application/checkid/_slug" */))
const _7d1cc5f6 = () => interopDefault(import('../pages/application/commercial/_slug.vue' /* webpackChunkName: "pages/application/commercial/_slug" */))
const _70b5b5aa = () => interopDefault(import('../pages/application/confirm/_slug.vue' /* webpackChunkName: "pages/application/confirm/_slug" */))
const _689f1924 = () => interopDefault(import('../pages/application/enquiry/_slug.vue' /* webpackChunkName: "pages/application/enquiry/_slug" */))
const _0c0e0d00 = () => interopDefault(import('../pages/application/feedback/_slug.vue' /* webpackChunkName: "pages/application/feedback/_slug" */))
const _c511dc52 = () => interopDefault(import('../pages/application/mortgage/_slug.vue' /* webpackChunkName: "pages/application/mortgage/_slug" */))
const _74eacbc7 = () => interopDefault(import('../pages/application/offer/_slug.vue' /* webpackChunkName: "pages/application/offer/_slug" */))
const _cf844a78 = () => interopDefault(import('../pages/application/property_owner/_slug.vue' /* webpackChunkName: "pages/application/property_owner/_slug" */))
const _7a0f7218 = () => interopDefault(import('../pages/application/result/_slug.vue' /* webpackChunkName: "pages/application/result/_slug" */))
const _633f93a6 = () => interopDefault(import('../pages/application/submitted/_slug.vue' /* webpackChunkName: "pages/application/submitted/_slug" */))
const _6b297e28 = () => interopDefault(import('../pages/p-loan/apply/_slug.vue' /* webpackChunkName: "pages/p-loan/apply/_slug" */))
const _9ea88c52 = () => interopDefault(import('../pages/account/_slug.vue' /* webpackChunkName: "pages/account/_slug" */))
const _1e3801ba = () => interopDefault(import('../pages/application/_slug.vue' /* webpackChunkName: "pages/application/_slug" */))
const _a2413784 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _acfa83a8 = () => interopDefault(import('../pages/card/_slug.vue' /* webpackChunkName: "pages/card/_slug" */))
const _d35e8240 = () => interopDefault(import('../pages/form/_slug.vue' /* webpackChunkName: "pages/form/_slug" */))
const _c2750ef8 = () => interopDefault(import('../pages/insurance/_slug.vue' /* webpackChunkName: "pages/insurance/_slug" */))
const _620de57e = () => interopDefault(import('../pages/landing/_slug.vue' /* webpackChunkName: "pages/landing/_slug" */))
const _c693a790 = () => interopDefault(import('../pages/mortgage/_slug.vue' /* webpackChunkName: "pages/mortgage/_slug" */))
const _07c07e6e = () => interopDefault(import('../pages/p-loan/_slug.vue' /* webpackChunkName: "pages/p-loan/_slug" */))
const _73f32781 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _9b3b49c2,
    name: "account"
  }, {
    path: "/blog",
    component: _9ed3f4f4,
    name: "blog"
  }, {
    path: "/card",
    component: _a98d4118,
    name: "card"
  }, {
    path: "/insurance",
    component: _bf07cc68,
    name: "insurance"
  }, {
    path: "/login",
    component: _495aff1b,
    name: "login"
  }, {
    path: "/logout",
    component: _50087b2e,
    name: "logout"
  }, {
    path: "/mortgage",
    component: _c3266500,
    name: "mortgage"
  }, {
    path: "/mortgage-match",
    component: _8f5c5510,
    name: "mortgage-match"
  }, {
    path: "/p-loan",
    component: _04533bde,
    name: "p-loan"
  }, {
    path: "/partner",
    component: _61c567fa,
    name: "partner"
  }, {
    path: "/privacy-policy",
    component: _5906e37b,
    name: "privacy-policy"
  }, {
    path: "/profile",
    component: _1b1da0ca,
    name: "profile"
  }, {
    path: "/query",
    component: _23e33dba,
    name: "query"
  }, {
    path: "/register",
    component: _e96baa72,
    name: "register"
  }, {
    path: "/terms-conditions",
    component: _404fec22,
    name: "terms-conditions"
  }, {
    path: "/vendor",
    component: _32a4f56c,
    name: "vendor"
  }, {
    path: "/application/done",
    component: _71045182,
    name: "application-done"
  }, {
    path: "/application/funding",
    component: _b6338b20,
    name: "application-funding"
  }, {
    path: "/application/mortgage",
    component: _c1a499c2,
    name: "application-mortgage"
  }, {
    path: "/application/p-loan",
    component: _7a822370,
    name: "application-p-loan"
  }, {
    path: "/blog/all",
    component: _54f4fed0,
    name: "blog-all"
  }, {
    path: "/enquiry/done",
    component: _2741af32,
    name: "enquiry-done"
  }, {
    path: "/enquiry/investment",
    component: _60522983,
    name: "enquiry-investment"
  }, {
    path: "/form/ua_prefill",
    component: _5d08320d,
    name: "form-ua_prefill"
  }, {
    path: "/insurance/auto",
    component: _47a9e830,
    name: "insurance-auto"
  }, {
    path: "/insurance/decoration",
    component: _318d7b29,
    name: "insurance-decoration"
  }, {
    path: "/insurance/helper",
    component: _6ffe0ba7,
    name: "insurance-helper"
  }, {
    path: "/insurance/labor",
    component: _db335da6,
    name: "insurance-labor"
  }, {
    path: "/insurance/store",
    component: _6efb609e,
    name: "insurance-store"
  }, {
    path: "/insurance/travel",
    component: _0fb1b7da,
    name: "insurance-travel"
  }, {
    path: "/login/forgotpassword",
    component: _03dbe788,
    name: "login-forgotpassword"
  }, {
    path: "/oauth2/redirect",
    component: _0e996122,
    name: "oauth2-redirect"
  }, {
    path: "/p-loan/calculator",
    component: _35183996,
    name: "p-loan-calculator"
  }, {
    path: "/p-loan/compare",
    component: _26b09b32,
    name: "p-loan-compare"
  }, {
    path: "/p-loan/form",
    component: _9a5b80d0,
    name: "p-loan-form"
  }, {
    path: "/p-loan/index%20copy",
    component: _caf6d858,
    name: "p-loan-index copy"
  }, {
    path: "/p-loan/lead",
    component: _23df17a0,
    name: "p-loan-lead"
  }, {
    path: "/p-loan/recommend",
    component: _2bd557be,
    name: "p-loan-recommend"
  }, {
    path: "/p-loan/short",
    component: _068ea6fe,
    name: "p-loan-short"
  }, {
    path: "/profile/contact",
    component: _ef4ca128,
    name: "profile-contact"
  }, {
    path: "/profile/loan_status",
    component: _7298d7ad,
    name: "profile-loan_status"
  }, {
    path: "/profile/requirement",
    component: _2d135162,
    name: "profile-requirement"
  }, {
    path: "/profile/resetpassword",
    component: _4f9006f6,
    name: "profile-resetpassword"
  }, {
    path: "/profile/reward",
    component: _323b3139,
    name: "profile-reward"
  }, {
    path: "/vendor/checkid",
    component: _7865d880,
    name: "vendor-checkid"
  }, {
    path: "/vendor/login",
    component: _6c1770c6,
    name: "vendor-login"
  }, {
    path: "/application/components/CommonFields",
    component: _2288e5ad,
    name: "application-components-CommonFields"
  }, {
    path: "/application/components/ContactTime",
    component: _f331e718,
    name: "application-components-ContactTime"
  }, {
    path: "/application/components/Identity",
    component: _28c66167,
    name: "application-components-Identity"
  }, {
    path: "/application/components/Step1",
    component: _234e3f8c,
    name: "application-components-Step1"
  }, {
    path: "/application/components/Step2",
    component: _235c570d,
    name: "application-components-Step2"
  }, {
    path: "/application/components/Step3",
    component: _236a6e8e,
    name: "application-components-Step3"
  }, {
    path: "/application/components/Step4",
    component: _2378860f,
    name: "application-components-Step4"
  }, {
    path: "/application/components/StepShort",
    component: _0f666cb7,
    name: "application-components-StepShort"
  }, {
    path: "/insurance/travel/apply",
    component: _4de3d7f2,
    name: "insurance-travel-apply"
  }, {
    path: "/p-loan/short/agree",
    component: _70ce73d8,
    name: "p-loan-short-agree"
  }, {
    path: "/application/additional/components/Step1",
    component: _43b896a4,
    name: "application-additional-components-Step1"
  }, {
    path: "/application/additional/components/Step3",
    component: _43d4c5a6,
    name: "application-additional-components-Step3"
  }, {
    path: "/application/approve/components/ApproveForm",
    component: _5658d51a,
    name: "application-approve-components-ApproveForm"
  }, {
    path: "/application/approve/components/BorrowerInfo",
    component: _ed5146d2,
    name: "application-approve-components-BorrowerInfo"
  }, {
    path: "/application/approve/components/LenderInfo",
    component: _58574ad1,
    name: "application-approve-components-LenderInfo"
  }, {
    path: "/application/commercial/components/Step1",
    component: _42a13761,
    name: "application-commercial-components-Step1"
  }, {
    path: "/application/commercial/components/Step2",
    component: _42af4ee2,
    name: "application-commercial-components-Step2"
  }, {
    path: "/application/commercial/components/Step3",
    component: _42bd6663,
    name: "application-commercial-components-Step3"
  }, {
    path: "/application/commercial/components/Step4",
    component: _42cb7de4,
    name: "application-commercial-components-Step4"
  }, {
    path: "/application/commercial/components/StepShort",
    component: _4bc11be8,
    name: "application-commercial-components-StepShort"
  }, {
    path: "/application/funding/components/Step1",
    component: _76515144,
    name: "application-funding-components-Step1"
  }, {
    path: "/application/funding/components/Step2",
    component: _76352242,
    name: "application-funding-components-Step2"
  }, {
    path: "/application/funding/components/Step3",
    component: _7618f340,
    name: "application-funding-components-Step3"
  }, {
    path: "/application/mortgage/components/Step1",
    component: _1fb62962,
    name: "application-mortgage-components-Step1"
  }, {
    path: "/application/mortgage/components/Step2",
    component: _1f99fa60,
    name: "application-mortgage-components-Step2"
  }, {
    path: "/application/mortgage/components/Step3",
    component: _1f7dcb5e,
    name: "application-mortgage-components-Step3"
  }, {
    path: "/application/mortgage/components/Step4",
    component: _1f619c5c,
    name: "application-mortgage-components-Step4"
  }, {
    path: "/application/submitted/components/ApplySubmitted",
    component: _6946b582,
    name: "application-submitted-components-ApplySubmitted"
  }, {
    path: "/p-loan/lead/components/Step1",
    component: _507b859e,
    name: "p-loan-lead-components-Step1"
  }, {
    path: "/p-loan/lead/components/Step2",
    component: _50899d1f,
    name: "p-loan-lead-components-Step2"
  }, {
    path: "/p-loan/lead/components/Step3",
    component: _5097b4a0,
    name: "p-loan-lead-components-Step3"
  }, {
    path: "/p-loan/lead/components/Step4",
    component: _50a5cc21,
    name: "p-loan-lead-components-Step4"
  }, {
    path: "/p-loan/lead/components/Step5",
    component: _50b3e3a2,
    name: "p-loan-lead-components-Step5"
  }, {
    path: "/p-loan/lead/components/Vendors",
    component: _296c7fa4,
    name: "p-loan-lead-components-Vendors"
  }, {
    path: "/p-loan/short/components/Step1",
    component: _ff05b3e0,
    name: "p-loan-short-components-Step1"
  }, {
    path: "/p-loan/short/components/Step2",
    component: _fee984de,
    name: "p-loan-short-components-Step2"
  }, {
    path: "/p-loan/short/components/Step3",
    component: _fecd55dc,
    name: "p-loan-short-components-Step3"
  }, {
    path: "/application/approve/second/:slug?",
    component: _58e02f9e,
    name: "application-approve-second-slug"
  }, {
    path: "/application/offer/confirm/:slug?",
    component: _5930db90,
    name: "application-offer-confirm-slug"
  }, {
    path: "/insurance/travel/apply/:slug",
    component: _4c2d36aa,
    name: "insurance-travel-apply-slug"
  }, {
    path: "/application/additional/:slug?",
    component: _3ddd29a2,
    name: "application-additional-slug"
  }, {
    path: "/application/approve/:slug?",
    component: _60b2b578,
    name: "application-approve-slug"
  }, {
    path: "/application/checkid/:slug?",
    component: _2a14eece,
    name: "application-checkid-slug"
  }, {
    path: "/application/commercial/:slug?",
    component: _7d1cc5f6,
    name: "application-commercial-slug"
  }, {
    path: "/application/confirm/:slug?",
    component: _70b5b5aa,
    name: "application-confirm-slug"
  }, {
    path: "/application/enquiry/:slug?",
    component: _689f1924,
    name: "application-enquiry-slug"
  }, {
    path: "/application/feedback/:slug?",
    component: _0c0e0d00,
    name: "application-feedback-slug"
  }, {
    path: "/application/mortgage/:slug",
    component: _c511dc52,
    name: "application-mortgage-slug"
  }, {
    path: "/application/offer/:slug?",
    component: _74eacbc7,
    name: "application-offer-slug"
  }, {
    path: "/application/property_owner/:slug?",
    component: _cf844a78,
    name: "application-property_owner-slug"
  }, {
    path: "/application/result/:slug?",
    component: _7a0f7218,
    name: "application-result-slug"
  }, {
    path: "/application/submitted/:slug?",
    component: _633f93a6,
    name: "application-submitted-slug"
  }, {
    path: "/p-loan/apply/:slug",
    component: _6b297e28,
    name: "p-loan-apply-slug"
  }, {
    path: "/account/:slug",
    component: _9ea88c52,
    name: "account-slug"
  }, {
    path: "/application/:slug?",
    component: _1e3801ba,
    name: "application-slug"
  }, {
    path: "/blog/:slug",
    component: _a2413784,
    name: "blog-slug"
  }, {
    path: "/card/:slug",
    component: _acfa83a8,
    name: "card-slug"
  }, {
    path: "/form/:slug?",
    component: _d35e8240,
    name: "form-slug"
  }, {
    path: "/insurance/:slug",
    component: _c2750ef8,
    name: "insurance-slug"
  }, {
    path: "/landing/:slug?",
    component: _620de57e,
    name: "landing-slug"
  }, {
    path: "/mortgage/:slug",
    component: _c693a790,
    name: "mortgage-slug"
  }, {
    path: "/p-loan/:slug?",
    component: _07c07e6e,
    name: "p-loan-slug"
  }, {
    path: "/",
    component: _73f32781,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
